export default{
  m:{
		//主页
		home:'HOME',
		products:'Thermal Paper',
		DualColorPaper:'Dual-Color Thermal Paper',
		UltrarPaper:'Ultra-resistant Thermal Paper',
		SyntheticPaper:'Synthetic PP Film',
		ThermalPaper:'Thermal Coated Art Paper',
		HighWashLabel:'High Temperature Thermal Wash Label',
		NormalWashLabel:'Normal Temperature Thermal Wash Label',

		//轮播图
		bannerimage1:'1_us.jpg',
		bannerimage2:'2_us.jpg',
		bannerimage3:'3_us.jpg',

		//模块内的轮播图
		materialbanner1:'banner_02_us.jpg',
		materialbanner2:'banner_01_us.jpg',
		materialbanner3:'banner_03_us.jpg',
		materialbanner4:'banner_06_us.jpg',
		materialbanner5:'banner_05_us.jpg',
		materialbanner6:'banner_04_us.jpg',


		Keywords:'keywords:',
		ThermosensitiveTwocolor:'thermosensitive two-color adhesive label paper',
		Heatsensitivewater:'heat sensitive water washing label',
		Sprayinktowashlabels:'spray ink to wash labels',
		doublesidethermalprinting:'double-side thermalprinting',
		transparentthermalppfilm:'transparent thermal pp film',


		FeaturesEnsuresHighQuality:'Features Ensure High Quality',

		functionIntroduction1:'Multiple specialized technologies and strong R&D capabilities enable us to break down technical barriers to achieve various special materials.',
		functionIntroduction2:'With advanced services and high quality production, we provide excellent composite printing materials for many large companies.',


		DirectDualColorThermalPrinting:'Direct Dual-Color Thermal Printing',
		functionIntroduction3:'Instant print in two colors; rich black to convey everyday information and dainty red to highlight core data. Convey your ideas to your client for marketing or engagement.',

		Shelflifeupto20Years:'Shelf life up to 5 Years',
		functionIntroduction4:"According to the testing standards of National Paper Product Quality Supervision Inspection Center, MASF's thermal printing materials have an average shelf life of up to 5 years, which is equivalent to carbon ribbon printing materials.We achieve great value at lower cost.",

		Scratchresistantand:'Scratch-resistant and temperature-resistant',
		Colourfastness:"Color fastness ≥ level 4, labels can be stored for a long time after transportation or washing & rubbing. At temperature up to 90°, MASF heat resistant material will not change color and is still easy to read.",


		NoPaperJam:'No Paper Jam',
		layerstructure:'Our thermal paper are made of different layers and are all top-coated. This ensures our printed images to be long-lasting. Top coat protects printhead from damaging. Paper will not stick to the head, and there will be no more paper jam.',

		Waterproofoilresistant:'Waterproof, oil resistant, ethanol resistant',
		Theprintedpaper:'The printed image does not lose color after rubbing with water. After soaking in oil for 3 hours, the handwriting is still clearly visible and the barcode can still be scanned.',

		characteristic:'Unique Technology · Extraordinary Quality · Trusted Brand by High-End Customers',

		Chooseour:'Choose our',
		mainreasons:'main reasons',
		details:"Integrated solution provider with special consumables, hardware and software MASF - Colour Drives Innovation",

		capabilityindustrialchain:'R&D capability of the whole industrial chain',
    Recognisedtheworld:'Recognised by many famous enterprises in the world',
		notonlyhasanumber:"MASF not only has a number of its own products developed with nanoscale thermal polymer composites, but also has the latest coating process and improved R&D equipment to guarantee the production quality of paper, care labels and other printing materials. At the same time, MASF united with the top printer manufacturers for hardware and printing equipment development.  MASF also created a cloud based printing solution with our first-class software R&D team.",

		ownfactory:'Own factory, first-class quality control',
		factoryoutlet:'Quality Ensured',
		laboratoryapplication:'MASF has its own R&D laboratory, application laboratory and production factory. With special coating technology & process and new customized production line, we can produce all kinds of paper, film and so on. We provide regular training for workers and monitor the whole production process to ensure the quality control of our products.',


		Completespecification:'Complete specification and varieties',
		Meetyourunique:'Meet your unique needs for consumables',
		Weprovideintegrated:'We provide integrated services with various printing materials, including Dual-colour direct thermal printing  paper, dual-colour PP, high-transparency and high-temperature resistant thermal PET, water resistant thermal care labels, double-side thermal printing paper, non-blotting inkjet substrates. All of the above substrates are supported by our corresponding printing equipment.',

		Weprovidereliable:'We provide reliable and high-quality products Growing together with our customers',
		Throughcontinuous:'Through continuous innovation and development, we endeavour to provide diversified one-stop comprehensive solutions for our customers.',

		Weprovidesolutions:'We provide solutions for various industries',
		

		Logistics:'Logistics',
		Homework:'Homework / paper printing',
		ClothingandFurniture:'Clothing and Furniture',
		Consumergoods:'Consumer goods',
		Catering:'Catering',
		TakeawayPOS:'Takeaway POS',

    //双色热敏纸
		Feature:'Feature',
		Higheffecient:'Masf two-color thermal paper - High effecient and low cost marketing tool',

		trait:'trait_us.png',
		restaurant:'restaurant_us.png',
		logistics:'logistics_us.png',
		hospital:'hospital_us.png',

		Strikewithcolour:'Strike the eye with colour!',
		Youcanprintout:'You can print out a variety of dual-colour combinations with a direct thermal printer, including red & black, purple & black, blue & black, yellow & black, etc',

		Rejectblack:'Reject black and white and mediocrity, express your expression with colors',
		Standoutfrom:'Stand out from the usual black & white tickets / labels and make your customers remember you differently!',


		Keepupwithwhat:"Keep up with what's hot!",
		exclusiveselfdeveloped:"MASF's exclusive self-developed coating enables direct thermal dual-colour printing. In different scenarios such as temporary promotions and holiday events, you can always print the most colourful promotion materials to meet the current events.",

    Customerswill:'Customers will  come into contact with your receipt or label all the time. Dual-colour can maximize the role of it, carrying ideas and conveying marketing information.',

		
		Marketing:"Marketing",
		Instantmarketing:'(Instant marketing, discount information, promotion, tracing the origin, etc)',

		LogisticsIndustry:'Logistics Industry',
		Byusingdualcolour:'By using dual-colour paper, it highlights the code of conduct that couriers need to implement, unifies the practices and reduces training costs. The eye-catching colours make it possible to achieve a low-cost, high-efficiency sorting and delivery service that is requested by the customer.',
		LogisticsManagement:'Logistics Management',
		efficientsorting:"(efficient sorting, accurate dispatch, fulfilment identification)",


		MedicalIndustry:'Medical Industry',
		Theuseofdualcolour:"Industrial applications include product compliance labels, industrial equipment labels, production handover sheets, labels markers for package, etc These are printed with a thermal transfer printer (with a ribbon) in the past. Now you can use dual-colour long time preservation of thermal direct printing. highlighting the key information and reducing the time and cost of ribbon changing.",
		HighEfficiency:'High Efficiency',
		Thermalprinting:'(Thermal printing, fast, efficient and identifiable sorting based on customized requirement)',

		TechnicalSpecification:'Technical Specification',

		Product:'Product',
		Model:'Model', 
		Specification:'Specification',
		Length:'Length',
		Introduction:'Introduction',

		Dualcolourthermalpaper:'Dual-colour \n thermal paper',
		title:'Simultaneous printing of two colours: ',
		Simultaneousprinting:"black & red, red & blue, black & green, black & purple, black & orange, etc. Dual-colour highlights key information, including distinctive features to attract attention to key information,and also has the advantages of fast and variable printing. See more details in our PPT presentation of various solutions for multi industies.",
		paperbicolorsizepng:'size_us.png',

		//四防热敏纸
		papersuitableListimg1:'suitable_01_us.png',
		papersuitableListimg2:'suitable_02_us.png',
		papersuitableListimg3:'suitable_03_us.png',

		papersizepng:'size_us.png',

		Textsremain:'Texts remain clear after soaking the paper in alcohol for 5 minutes.',
		Spraythepaper:"Spray the paper with 3-5ml 75°sterilizing alcohol and wait for 5 minutes. Rub the surface of paper more than 10 times and the text is still clear. These are MASF's quality standards of Ultra-Resistant products.",
		papertrait_01:'trait_01_us.png',

		Thehandwriting:'The handwriting is still clear after soaking in room temperature water for 24 hours',
		Soakinroom:'Soak in room temperature water for 24 hours, with a color density still at 0.9100 degrees Celsius and blanch in high temperature boiling water for 1 minute. The handwriting is still sharp.',
		papertrait_02:'trait_02_us.png',

		Textdensityimmersion:'Text remain clear and color density is still ≥1.0 after immersion in oil for 48 hours.',
		Thepapercan:'The paper can be used in kitchen for food preparation, instant food packaging, hot pot marking, etc. It is also suitable for environment that needs long time resistance to oil to solve the problem of labels contaminated with oil.',
		papertrait_03:'trait_03_us.png',

		plasticizerandscratch:'plasticizer and scratch resistant',
		Afterwrapping:"After wrapping MASF's ultra resistant products with wrapping film or transparent tape for more than 6 months, texts can still be identified with color density ≥0.8. Our scratch-resistant performance is certified by SGS. The labels will no longer be scratched during transportation.",
		papertrait_04:'trait_04_us.png',

		Applications:'Applications',
		MedicalLogisticsCatering:'Medical, Logistics, Catering, etc',

		TechnicalSpecification:'Technical Specification',
		Ultraresistant:'MASF Ultra-resistant thermal printing paper - The Lone Ranger of Specialty Printing Papers',

		Fourproofthermalsensitivepaper:'Ultra Resistant Thermal Paper',

		Waterprooftitle:'Waterproof:',
		Waterproof:' immersed in water of room temperature for 24 hours and the color density remains ≥0.9;',
		Alcoholresistanttitle:'Alcohol resistant:',
		Alcoholresistant:' spray with 75 ℃ sterilized alcohol and wait for 5 minutes. Then rub the paper 10 times and the text is still clear;',
		Oilresistanttitle:'Oil resistant: ',
		Oilresistant:'after 48 hours of oil immersion, the color density is still ≥0.8;',
		Antiscratchtitle:'Anti-scratch:',
		Antiscratch:'certified by SGS with color fastness ≥ grade 4;',
		Plasticizerresistanttitle:'Plasticizer resistant:',
		Plasticizerresistant:' after wrapping with transparent film or tape paste for 72 hours, the text is still clear;',

		Plasticizerresistanttitle1:'Plasticizer resistant:',
		Plasticizerresistant1:' after wrapping with wrapping film or tape paste for 3 months, the text is still clear with colour density ≥0.8;',
		Scratchresistanttitlte1:'Scratch resistant: ',
    Scratchresistant1:'Do not lose color or peel after tearing with 3M tape for 30 times. Certified by SGS;',
		Longshelflifetitle1:'Long shelf life: ',
		Longshelflife1:'shelf life ≥ 2 years, certified by the National Testing Center',
		Inkprintabletitle1:'Ink printable: ',
		Inkprintable1:'can be printed with inket printer',
		Waterprooftitle1:'Waterproof: ',
		Waterproof1:' After immersed in water of room temperature for 24 hours and the color density remains ≥1.0',
		Oilresistanttitle1:'Oil resistant：',
		Oilresistant1:'After 24 hours of oil immersion, the color density is still ≥1.0;',
		Highcolourdensitytitle1:'High colour density:',
		Highcolourdensity1:' colour density ≥1.3. Printout has rich and clear color.',



		//热敏合成纸
		ProductFeatures:'Product Features',
		MultipleAdvantages:'Multiple advantages for various applications',

		Plasticresistant:'Plastic resistant',
		Theprintingeffecttape:'The printing effect is still clear after 7 days of pasting with wrapping film and transparent tape',
		Scratchresistant:'Scratch resistant',
		Donotlose:'Do not lose color after pape tearing for 30 times',
		Longshelflife:'Long shelf life',
		Shelflife:'Shelf life ≥ 2 years, certified by the National Testing Center',
		InkPrintable:'Ink Printable',
		Canbeprintedwithinkjetprinter:'Can be printed with inkjet printer',
		Waterproof:'Waterproof',
		Afterimmersed:'After immersed in water of room temperature for 24 hours and the color density remains ≥1.0',
		OilResistant:'Oil Resistant',
		Afterhoursimmersion:'After 24 hours of oil immersion, the color density is still ≥1.0',


		Applications23:'Product Display',
		Suitableperformances:'Suitable for various environments with outstanding performances.',

		Suitableforindustries:'Suitable for a wide range of industries',
		FurnitureindustryPrintingindustry:'Furniture industry; Medical industry; Electrical and electronic appliances; Industrial warehouse; Logistics and transportation; Industrial sector; Printing industry',


		TechnicalSpecification:'Technical Specification',

		paperCompoundsize:'size_us.png',
		paperCompounddisplay:'display_01_us.jpg',

		//热敏铜版纸
		ProductFeatures:'Product Features',
		Multipleadvantages:'Multipleadvantages',

		NoNeedforRibbon:'No Need for Ribbon',
		Ribbonlessprinting:'Ribbonless printing eliminates the need for frequent ribbon replacement, making the operation more efficient and convenient. It is also more environment friendly and can avoid the generation of solid waste.',
		Reducecost:'Reduce cost and say goodbye to ribbons',


		Waterproof:'Waterproof',
		Textremainclearafter:'Text remain clear after immersed in water for 30 minutes',

		OilResistant2:'Oil Resistant',
		Afterthecolordensityis:'After 28 hours of oil immersion, the color density is still ≥1.0',

		ScratchResistant2:'Scratch Resistant',
		Textremainclearafterscratching:'Text remain clear after scratching back and forth with a hard object',
		PlasticizerResistant2:"Plasticizer Resistant",
		Nopeelandloseofcolorafter:'No peel and lose of color after friction & wrapped with tape during long transportation; Certified with SGS.',

		Detaileddisplay:'Detailed display',
    Qualityassurance:'Quality assurance',

    Clearprinting:'Clear printing',
		Thesurfacesmoothandcleanandtheprinting:'The surface of the paper is smooth and clean, and the printing effect is clear. No peel or loss of color after friction or wrapped with tape during long-distance transportation, certified by SGS',

		Directsalesfrommanufacturer:'Direct sales from manufacturer with quality assurance',
		Weadoptehighqualityrawmaterials:'We adopte high quality raw materials and strict control in production, and manufacture high quality and premium products.',

		Products1:'Products',
		Qualityensuredandsuitable:'Quality ensured and suitable for harsh environment',

		Suitableforvariousindustries:'Suitable for various industries',

		Noneedofribbontitle:'No need of ribbon: ',
		Noneedofribbon:'no need of frequent replacement of ribbons, the operation is more efficient and convenient. It is also more environment friendly and can avoid the generation of solid waste.',

		copperLongshelflifetitle:'Long shelf life: ',
		copperLongshelflife:'With the use of a special coating, the stability of the printing effect has been improved with richer color with shelf life ≥5 years as certified by the National Paper Testing Center.',

		Highersmoothnessofsurfacetitle:'Higher smoothness of surface:',
		Highersmoothnessofsurface:'effectively protect the print head; the paper feels delicate and smooth, which is close to the skin texture;',
		Goodresistancetoharshenvironmenttitle:'Good resistance to harsh environment: ',
		Goodresistancetoharshenvironment:'waterproof, oilproof, scratch-resistant, plasticizer resistant, suitable for various scenarios;',

		paperCoppersuitableListimg1:'suitable_01_us.jpg',
		paperCoppersuitableListimg2:'suitable_02_us.jpg',
		paperCoppersuitableListimg3:'suitable_03_us.jpg',
		paperCoppersuitableListimg4:'suitable_04_us.jpg',

		paperCoppersuisize:'size_us.png',
		//热敏高温水洗唛

		washhighProductFeatures:'Product Features',
		washhighHightemperaturewashlabels:'High-temperature wash labels',

		washhightraitListHighTemperatureresistant: 'High-Temperature resistant',
		washhightraitListNodiscoloration:'No discoloration with steam ironing',
		washhightraitListResistanttohigh: "Resistant to high temperature ironing; Resistant to 5 times of 130°C steam ironing ",
		washhightraitListResistant:'130°C high temperature resistant',

		washhightraitListWaterResistant:'Water Resistant',
		washhightraitListtimesofmachine:'5 times of machine wash at 60°C with laundry detergent for 45 minutes each time.',
		washhightraitListLongshelflife:' Long shelf life',
		washhightraitListlastingthermal:'Long lasting thermal material developed by MASF: the color will remain intact for 10 years.',
		washhightraitListAlcoholresistant:'Alcohol resistant',
		washhightraitListNocolorfadingafter:'No color fading after immersing in alcohol for more than 5 minutes',
		washhightraitListCompatiblewithvariousprinters:'Compatible with various printers',
		washhightraitListNoneedforspecific:"No need for specific high-temperature print head,  normal models of Zebra, TSC, etc can have uniform and excellent printing quality.",
		
		washhightraitListProducts:'Products',
		washhightraitListYouridealwashlabel:'Your ideal wash label',
		washhightraitListNoneedof:'No need of ribbon printing, low cost and more efficient',
		

		washhightraitListApplicableindustries:' Applicable industries',
		washhightraitListMeettheneedsof:'Meet the needs of multiple industries and environment',


		washhightraitListShoesandhatsindustry:'Shoes and hats industry',
		washhightraitListSmoothsurface:'Smooth surface; suitable for printing logo and label information; firm and no loss of color',

		washhightraitListConsumergoodsindustry:'Consumer goods industry',
		washhightraitListResistanttodry:'Resistant to dry friction; Friction resistance test reaches Grade 4 as certified by SGS',

		washhightraitListClothingindustry:'Clothing industry',
		washhightraitListUsedaslabels:'Used as labels of clothing; No deformation and de-coloring with high temperature ironing machine',

		washhightraitListFoodandfeedingpackaging:'Food and feeding packaging',
		washhightraitListThermalprinting:'Thermal printing is more efficient, convenient and scratch-resistant',


		tableDataHighTemperatureThermalWashLabel:'High Temperature Thermal Wash Label',

		tableDataNocolourfadingtitle:'Washable:',
		tableDataNocolourfading:' No colour fading or delamination after 5 washes with 60°C water and detergent (45 minutes each);',

		tableDataScratchResistanttitle:'Scratch Resistant:',
		tableDataScratchResistant:' color fastness in dry friction test reaches ≥Grade 4;',
		
		tableDataLongshelflifetitle:'Long shelf life:',
		tableDataLongshelflife:" MASF's R&D team developed long-term preservation technology of heat-sensitive materials. No color fading after ten years;",

		tableDataHighcolordensitytitle:'High color density and clear texts:',
		tableDataHighcolordensity:'high color density and clearly texts with thermal printing;',
		
		tableDataCostsavingandwastetitle:'Cost saving and waste reduction:',
		tableDataCostsavingandwaste:'in traditional ribbon printing the ribbon needs to be replaced from time to time, which increases the cost. While thermal printing is not only cost-saving but also first choice for environmental protection;',

		tableDataAlcoholresistanttitle:'Alcohol resistant:',
		tableDataAlcoholresistant:'no color fading after immersion in alcohol for more than 5 minutes.',

		washHighsize:'size_us.jpg',

	 //热敏常温水洗唛
	 washNormalProductFeatures:'Product Features',
	 washNormalNormalTemperature:' Normal Temperature Thermal Wash Label',

	 washNormaltraitListWashable:'Washable',
	 washNormaltraitListNocolourfading:'No colour fading or delamination after 60 minutes of washing with 40°C water;',
	 washNormaltraitListScratchResistant:'Scratch Resistant',
	 washNormaltraitListcolorfastness :'color fastness in dry friction test reaches ≥Grade 4;',

	 washNormaltraitListLongshelflife:'Long shelf life',
	 washNormaltraitListteamdeveloped:"MASF's R&D team developed long-term preservation technology of heat-sensitive materials. No color fading after ten years;",
	 washNormaltraitListAlcoholresistant:'Alcohol resistant',
	 washNormaltraitListnocolorfading: 'no color fading after immersion in alcohol for more than 5 minutes.',
	  
	 washNormaltraitListFourmajorcharacteristics:'Four major characteristics',
	 washNormaltraitListLetyouchooseus:'Let you choose us',

	 washNormaltraitListCostsaving:'Cost saving and waste reduction',
	 washNormaltraitListIntraditional:'In traditional ribbon printing, the ribon needs to be replaced from time to time, which increases the cost. While thermal printing is not only cost-saving but also first choice for environmental protection;',

	 
	 washNormaltraitListTraditionalribbonprinting:'Traditional ribbon printing',
	 washNormaltraitListwashNormaltraitList:'Complex operation, not environment friendly; more limitations;',

	 washNormaltraitListThermalprinting:'Thermal printing - no need for ribbon',
	 washNormaltraitListnoribbon:'no ribbon, low printing cost, environment friendly, high color density, clear texts;',


	 washNormaltraitListProducts:'Products',
	 washNormaltraitListYouridealwashlabel:'Your ideal wash label',

	 washNormaltraitListApplicableindustries:'Applicable industries',
	 washNormaltraitListMeettheneeds :'Meet the needs of multiple industries and environment',


	 washNormaltraitListHomeTextile:'Home Textile',
	 washNormaltraitListItcanbeused:'It can be used as labels for household goods, such as sofa, bed sheet, bath towels, which can be kept for a long time.',

	 washNormaltraitListLuggage:'Luggage',
	 washNormaltraitListUsedongoods: 'Used on goods that do not require high temperature ironing, such as luggage.',

	 washNormaltraitListPackaging:'Packaging',
	 washNormaltraitListPackagingsealing:'Packaging sealing labels for rice, flour, grain, etc., no ribbon required with long term storage.',

	 washNormaltraitListtag:'tag',
	 washNormaltraitListLabelsfor:'Labels for various kinds of winding marking',
	 
	 washNormaltraitListWashabletitle:'Washable:',
	 washNormaltraitListWashableNocolour:' No colour fading or delamination after washing with 40°C water for 60 minutes;',

	 washNormaltraitListScratchResistanttitle:'Scratch Resistant:',
	 washNormaltraitListScratchResistant:' color fastness in dry friction test reaches ≥Grade 4;',

	washNormaltraitListLongshelflifetitle:'Long shelf life:',
	washNormaltraitListLongshelflife:" MASF's R&D team developed long-term preservation technology of heat-sensitive materials. No color fading after ten years;",

	washNormaltraitListHighcolortitle:'High color density and clear texts:',
	washNormaltraitListHighcolor:'high color density and clearly texts with thermal printing;',

  washNormaltraitListCostsavingandwastetitle:'Cost saving and waste reduction: ',
	washNormaltraitListCostsavingandwaste:'in traditional ribbon printing the ribbon needs to be replaced from time to time, which increases the cost. While thermal printing is not only cost-saving but also first choice for environmental protection;',
	washNormaltraitListAlcoholresistanttitle:'Alcohol resistant:',
	washNormaltraitListAlcoholresistant:' no color fading after immersion in alcohol for more than 5 minutes.',

	washNormaldisplay1:'display_01_us.jpg',
	washNormaldisplay2:'display_02_us.jpg',
	washNormaldisplay3:'display_03_us.jpg',

		//标签设计
		LabelDesign:'Label Design Platform',
		Allinonesolutiontool:'All-in-one solution tool',
    MASFLabelEditor:'· MASF Label Editor ·',

		introduce:'A software to realize multi-industry label printing and provide comprehensive solutions including online design, cutting, task issuance, print job supervision and management, collateral management, account functions, etc. MASF Label Editor is an one-stop tool to complete the barcode design, production and management.',

		IntroductiontoFunction:'Introduction to Function',

		PDFImportSplit:'PDF Import - Split',
		Noneedtomanually:'No need to manually slice PDF templates, single-task splitting for multiple tags on one page',

		ControlofInkjetorthermalprinter:'Control of Inkjet or thermal printer',
		Supportsinkjetprinters:'Supports inkjet printers, management of printheads and other configurations to make your printing more convenient.',

		DefaultandGeneral:'Default and General Settings modification',
		Printheadconfiguration:'Printhead configuration',
		Developermode:'Developer mode',

		BatchPrinting:'Batch Printing',
		Batchprintingcanbeachieved:'Batch printing can be achieved by customer group or orders.',

		Supportsaddinggroupsintoprintqueues:'Supports adding groups into print queues',
		Inkjetthermalprintingsupport:'Inkjet/thermal printing support',
		Supportbatchprinting:'Support batch printing',
		Inkjetprintingdevicemanagement:'Inkjet printing device management',

		AccountManagement:'Account Management',
		Youcansetupteamdepartment:'You can set up team/department accounts and manage permissions of each account.',
		Assignprivilegestodifferentaccounts:'Assign privileges to different accounts',
		Assigntaskstosubaccounts:'Assign tasks to sub-accounts',

		TemplateCenter:'Template Center',
		Hugeamountoftemplates:'Huge amount of templates',
		Manykindsofmaterials:'Many kinds of materials, covering a wide range of industries',
		Collecttemplates:'Collect templates and share tags to the template center.',
		Supporttemplatesediting:'Support templates editing',

		WeProvideIntegrated:'We Provide Integrated and Intelligent Solutions for Our Clients',

		MASFCloudPrintingPlatform:'MASF Cloud Printing Platform',
		Cloudprintingmanagementplatform:'Cloud printing management platform',
		Thefirstdomesticdual:'The first domestic dual color thermal printing solution provider, with a prominent focus on dual color small ticket content and high marketing conversion rate, can effectively improve store operation efficiency and repurchase rate, and reduce operating costs caused by catering errors, sorting errors, and other factors.',

		trait_03:'trait_03_us.png',
		trait_04:'trait_04_us.png',
		display_01:'display_01_us.png',
		display_02:'display_02_us.png',
		display_03:'display_03_us.png',
		display_04:'display_04_us.png',
		display_05:'display_05_us.png',
		display_06:'display_06_us.png',



		//云打印平台
		cloudPrint:'CloudPrintingPlatform',
  }
}