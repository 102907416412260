export default{
  m:{
		// 主页
		home:'首页',
		products:'热敏耗材',
		DualColorPaper:'双色热敏纸',
		UltrarPaper:'四防热敏纸',
		SyntheticPaper:'热敏合成纸',
		ThermalPaper:'热敏铜版纸',
		HighWashLabel:'热敏高温水洗唛',
		NormalWashLabel:'热敏常温水洗唛',

		//轮播图
		bannerimage1:'1.jpg',
		bannerimage2:'2.jpg',
		bannerimage3:'3.jpg',

		//模块内的轮播图
		materialbanner1:'banner_02.jpg',
		materialbanner2:'banner_01.jpg',
		materialbanner3:'banner_03.jpg',
		materialbanner4:'banner_06.jpg',
		materialbanner5:'banner_05.jpg',
		materialbanner6:'banner_04.jpg',


		Keywords:'热门关键词:',
		ThermosensitiveTwocolor:'热敏双色不干胶标签纸',
		Heatsensitivewater:'热敏水洗唛',
		Sprayinktowashlabels:'喷墨水洗唛',
		doublesidethermalprinting:'热敏双面打印',
		transparentthermalppfilm:'热敏pp透明膜',

		FeaturesEnsuresHighQuality:'大产品特性提供品质保障',

		functionIntroduction1:'多个专项技术与过硬的研发实力让我们能够打破技术壁垒实现多种特殊材料研发',

		functionIntroduction2:'国际先进服务水准和国内优质生产水平成功的为国内外众多大型企业提供成熟的复合打印材料产品',

		DirectDualColorThermalPrinting:'即时打印双色标签',
		functionIntroduction3:'双色即时打印，黑色浓郁，传递日常信息；红色娇艳，凸显核心数据。无论是营销、还是管理，向用户传达你的心智。',

		Shelflifeupto20Years:'保存时长高达二十年',
		functionIntroduction4:"按照国家纸张检测中心检测标准，马斯夫热敏打印材料，平均保存时间高达二十年，达到碳带打印保存标准，低成本实现大价值。",


		Scratchresistantand:'耐刮擦、耐高温',
		Colourfastness:"色牢度达到4级以上，标签经过运输或者水洗摩擦皆可长时间保存，温度达到90°，马斯夫材料不会自然发色，始终透亮。",

		NoPaperJam:'不卡纸',
		layerstructure:'三层立体结构涂层方式，持久显色，涂层平滑不损伤打印头不粘头不卡纸，打印后纸张表面在高倍显微镜下无擦痕。',


		Waterproofoilresistant:'防水、防油、防乙醇',
		Theprintedpaper:'水洗不掉、油浸不花，在油中浸泡3小时，字迹依然清晰可见，条码依然可以扫描。',

		characteristic:'专项技术·非凡动力·高端客户信赖品牌',

		Chooseour:'选择我们的',
		mainreasons:'大理由',
		details:'基于特种耗材软硬件一体的综合解决方案供应商，马斯夫新材料 色彩驱动变革让同行者共赢共生',

		capabilityindustrialchain:'全产业链研发能力',
    Recognisedtheworld:'深受世界众多知名企业的认可',
		notonlyhasanumber:"马斯夫不仅拥有纳米级热敏高分子复合材料研发的多个自有产品，还拥有最新涂层工艺和改进的研发设备保障纸张、水洗唛等承印材料的生产品质。同时，马斯夫联合国内外顶尖打印机公司进行硬件控制程序和打印设备，更拥有国内一流软件研发团队，以打造基于云打印的物联网行业解决方案。",


		ownfactory:'自有工厂、品控一流',
		factoryoutlet:'工厂直销',
		laboratoryapplication:'马斯夫拥有自己的研发实验室、应用实验室与生产工厂，采用了特殊涂层技术和涂布工艺，全新定制产线，适用于各种纸张、膜类等生产。定期对工人开展各项培训，生产产品全程检验，精益求精做好产品品控。',

		Completespecification:'规格多，品种齐全',
		Meetyourunique:'满足您对于耗材的各种个性化需求',
		Weprovideintegrated:'各种承印材料、打印设备综合研发，提供一体化服务。即时热敏打印的双色纸和双色PP、高透明度耐高温热敏PET、水洗不掉色的热敏水洗唛、双面热敏打印双胶纸、不洇墨喷墨基材......以上基材皆具备对应的打印设备作为支撑。',


		Weprovidereliable:'为市场提供精品——与客户共同成长',
		Throughcontinuous:'通过持续创新与发展，努力为客户提供多元化的一站式服务解决方案。',

		Weprovidesolutions:'为各行业提供落地解决方案',
	

		Logistics:'快递物流行业',
		Homework:'家庭、商用打印行业',
		ClothingandFurniture:'服装家居行业',
		Consumergoods:'日用品行业',
		Catering:'餐饮行业',
		TakeawayPOS:'外卖POS行业',


		// 双色热敏纸
		Feature:'产品特点',
		Higheffecient:'马斯夫双色热敏纸-高时效低成本的营销工具',

		trait:'trait.png',
		restaurant:'restaurant.png',
		logistics:'logistics.png',
		hospital:'hospital.png',

		Strikewithcolour:'冲击您的眼球，用色彩先声夺人',
		Youcanprintout:'用热敏打印机即可任意打印出多种双色搭配，红黑、紫黑、蓝黑...',


		Rejectblack:'拒绝黑白和平庸，用色彩表达您的表达 ',
		Standoutfrom:'从千篇一律的黑白小票和标签中脱颖而出，让客户记住不一样的您',

		Keepupwithwhat:"紧跟热点，宣发文案随心打印",
		exclusiveselfdeveloped:"马斯夫独家自研涂层，实现热敏双色打印。在临时促销、节假日活动等不同场景，随时打印符合时事的出彩宣传文案",
		Customerswill:'标签小票、作为用户在用餐各个环节都会接触到的物品，双色能最大程度发挥出其本身能够承载文字、传播营销信息的作用。',
		Marketing:"营销",
		Instantmarketing:'(小票即时营销、折扣活动、推销活动、溯源等)',


		LogisticsIndustry:'物流行业',
		Byusingdualcolour:'通过双色面单纸，突出显示快递员需要执行的行为规范，统一心智、降低培训成本。醒目的颜色更能实现低成本、高效率分拣派送服务，明确客户需求。',
		LogisticsManagement:'管理',
		efficientsorting:"（高效分拣、精准派送、履约识别）",

		MedicalIndustry:'医疗行业',
		Theuseofdualcolour:'运用双色标签进行分拣批次管理，让彩色色块识别不同部门+文字凸显重要信息，可帮助医院化验分拣人员解决这一痛点。热敏双色管理既具备标识鲜明的特点，又具备热敏快捷高效可变打印的优势。',
		HighEfficiency:'高效',
		Thermalprinting:'(热敏打印，千人千面 ，快速分拣可识别)',

		TechnicalSpecification:'产品规格、特性',

		Product:'产品名称',
		Model:'产品型号', 
		Specification:'产品规格',
		Length:'米数',
		Introduction:'介绍',

		Dualcolourthermalpaper:'双色热敏纸',
		title:'同时打印两种颜色：',
		Simultaneousprinting:'黑红、红蓝、黑绿、黑紫、黑玫双色热敏突出重点信息，既具备标识鲜明的特点吸引注意力，传递关键信息，又具备热敏快捷高效可变打印的优势，具体可参考餐饮行业、多行业双色解决方案等ppt；',

		paperbicolorsizepng:'size.png',

		//四防热敏纸

		papersuitableListimg1:'suitable_01.png',
		papersuitableListimg2:'suitable_02.png',
		papersuitableListimg3:'suitable_03.png',

		papersizepng:'size.png',

		Textsremain:'酒精浸泡字体5分钟字迹依然清晰',
		Spraythepaper:"75°消毒酒精直接喷洒3-5毫升在纸上，5分钟后在打印面反复摩擦10次以上字体依然清晰，这才是马斯夫为您提供的合格四防产品。",
		papertrait_01:'trait_01.png',
	
		Thehandwriting:'常温水浸泡24小时字迹依然清晰',
		Soakinroom:'常温水浸泡24小时，色密度仍在0.9，100度高温沸水冲烫1分钟，字迹还是杠杠的。',
		papertrait_02:'trait_02.png',

		Textdensityimmersion:'油滴浸泡48小时字体不褪色，字迹依然清晰',
		Thepapercan:'油浸泡48小时后色密度仍然高达1.0以上, 可用于后厨备餐、速食包装火锅底料标签等需要长时间耐油污的环境，为餐饮行业解决标签怕油污的问题。',
		papertrait_03:'trait_03.png',

		plasticizerandscratch:'耐刮擦、耐塑性强',
		Afterwrapping:"马斯夫四防耐塑性强，使用缠绕膜或透明胶带粘贴缠绕，可保证6个月后打印面字迹可辨识，色密度在0.8以上 ；耐刮擦性能更是经过sgs专业国际机构认证 ，货物运输过程中再不怕标签刮花脱落。",
		papertrait_04:'trait_04.png',

		Applications:'适用行业',
		MedicalLogisticsCatering:'医疗、物流、餐饮等行业',

		TechnicalSpecification:'产品规格',
		Ultraresistant:'马斯夫四防热敏纸 做特种纸中的孤勇者',


		Fourproofthermalsensitivepaper:'四防热敏纸',

		Waterprooftitle:'防水：',
		Waterproof:'常温水浸泡24小时，色密度依旧0.9；',
		Alcoholresistanttitle:'防酒精：',
		Alcoholresistant:'75℃的消毒酒精喷洒5分钟后摩擦10次依旧清晰；',
		Oilresistanttitle:'防油：',
		Oilresistant:'油浸泡48小时，色密度依旧0.8；',
		Antiscratchtitle:'防刮擦：',
		Antiscratch:'SGS国际检测机构专业认证，四级以上色牢度；',
		Plasticizerresistanttitle:'耐塑：',
		Plasticizerresistant:' 透明膜或者胶带粘贴72小时仍然字迹清晰；',


		Plasticizerresistanttitle1:'耐塑性：',
		Plasticizerresistant1:'使用缠绕膜或透明胶带粘贴缠绕，可保证3个月后打印面字迹可辨识，色密度在0.8以上；',
		Scratchresistanttitlte1:'耐刮：',
    Scratchresistant1:'sgs国际检测机构专业检测，3M胶带撕拉30次不掉色不破损；',
		Longshelflifetitle1:'长效保存：',
		Longshelflife1:'按照国家纸张质量监督检验中心检测标准，至少达到2年保存时长；',
		Inkprintabletitle1:'可印刷：',
		Inkprintable1:'可用油墨进行印刷；',
		Waterprooftitle1:'防水：',
		Waterproof1:'常温水浸泡24小时，色密度仍在1.0；',
		Oilresistanttitle1:'防油：',
		Oilresistant1:'油滴浸泡24小时，色密度仍在1.0；',
		Highcolourdensitytitle1:'色密度高：',
		Highcolourdensity1:'色密度高达1.3以上，显色浓郁清晰；',


		//热敏合成纸
		ProductFeatures:'产品特点',
		MultipleAdvantages:'多种特性，适用多种场景',

		
		Plasticresistant:'耐塑',
		Theprintingeffecttape:'缠绕膜、透明胶带粘贴7天打印效果依旧清晰',
		Scratchresistant:'耐刮',
		Donotlose:'胶带撕拉30次不掉色',
		Longshelflife:'长效保存',
		Shelflife:'按照国家检测中心检测至少保存2年',
		InkPrintable:'可印刷',
		Canbeprintedwithinkjetprinter:'可用油墨进行印刷',
		Waterproof:'防水',
		Afterimmersed:'A常温水浸泡24小时，色密度仍在1.0',
		OilResistant:'防油',
		Afterhoursimmersion:'油滴浸泡24小时，色密度仍在1.0',

		Applications23:'产品展示',
		Suitableperformances:'实验多个场景，性能出色不惧考验',

		Suitableforindustries:'适用多个行业',
		FurnitureindustryPrintingindustry:'家具行业  医疗行业  电子电器  工业仓储  物流运输  工业领域  印刷行业',

		TechnicalSpecification:'产品规格、特性',


		paperCompoundsize:'size.png',
		paperCompounddisplay:'display_01.jpg',

		//热敏铜版纸
		ProductFeatures:'产品特点',
		Multipleadvantages:'产品特性测试展示',

		NoNeedforRibbon:'无需碳带',
		Ribbonlessprinting:'无需碳带打印，不需要专岗专职人员操作，不再频繁更换碳带，操作更加高效便捷；同时也更加环保，避免产生固体废弃物',
		Reducecost:'告别碳带、降低成本',
		Waterproof:'防水',
		Textremainclearafter:'水中浸泡30分钟以上字迹依然清晰',
		OilResistant2:'防油',
		Afterthecolordensityis:'油中浸泡28小时，色密度仍在1.0以上',
		ScratchResistant2:'防刮擦',
		Textremainclearafterscratching:'硬物来回刮擦，字迹依旧清晰',

		PlasticizerResistant2:"耐塑",
		Nopeelandloseofcolorafter:'sgs国际检测机构专业 检测，长途运输摩擦， 胶带粘贴不掉色不破损',

		Detaileddisplay:'细节展示',
    Qualityassurance:'品质保证、无惧考验',

		Clearprinting:'打印清晰',
		Thesurfacesmoothandcleanandtheprinting:'面材平整光洁，不毛糙，打印效果清晰可见，sgs国际检测机构专业检测，长途运输摩擦，胶带 粘贴不掉色不破损',

		Directsalesfrommanufacturer:'工厂直销、品质保证',
		Weadoptehighqualityrawmaterials:'采用优质品牌原材料，生产车间严格把控，制造高品质的优质产品',


		Products1:'产品展示',
		Qualityensuredandsuitable:'品质保证、无惧考验',
		

		Suitableforvariousindustries:'行业应用广泛',

		Noneedofribbontitle:'无需碳带打印：',
		Noneedofribbon:'不需要专岗专职人员操作，不再频繁更换碳带，操作更加高效便捷；同时也更加环保，避免产生固体废弃物；',

		copperLongshelflifetitle:'长久保存：',
		copperLongshelflife:'使用了涂层特殊，打印效果稳定性得以改进，发色浓郁，通过国家纸张检测中心标准验证长效保存五年以上；',

		Highersmoothnessofsurfacetitle:'纸张表面平滑度更高：',
		Highersmoothnessofsurface:'有效保护打印头，手感细腻光滑，贴近皮肤肌理；',
		Goodresistancetoharshenvironmenttitle:'耐候性好：',
		Goodresistancetoharshenvironment:'防水防油防刮擦耐塑，适用于多种场景；',

		paperCoppersuitableListimg1:'suitable_01.jpg',
		paperCoppersuitableListimg2:'suitable_02.jpg',
		paperCoppersuitableListimg3:'suitable_03.jpg',
		paperCoppersuitableListimg4:'suitable_04.jpg',
		paperCoppersuisize:'size.png',

		//热敏高温水洗唛

		washhighProductFeatures:'产品特点',
		washhighHightemperaturewashlabels:'高温水洗唛',

		washhightraitListHighTemperatureresistant: '耐高温',
		washhightraitListNodiscoloration:'蒸汽熨烫不发色',
		washhightraitListResistanttohigh: "耐高温熨烫，130度蒸汽熨斗熨5次",
		washhightraitListResistant:'耐高温130度',

		washhightraitListWaterResistant:'耐水洗',
		washhightraitListtimesofmachine:'60°机洗，每次洗45分钟，洗5次，洗涤的时候会放洗衣液',
		washhightraitListLongshelflife:'长久保存',
		washhightraitListlastingthermal:'团队经过长时间研发的可长久保存的热敏材料，十年仍不褪色',
		washhightraitListAlcoholresistant:'防酒精',
		washhightraitListNocolorfadingafter:'可浸泡在酒精中超过5分钟不褪色',
		washhightraitListCompatiblewithvariousprinters:'支持多型号打印',
		washhightraitListNoneedforspecific:"不需要特定的高温打印头，TSC、斑马正常型号都能均匀出色、保持高清晰",

		washhightraitListProducts:'产品展示',
		washhightraitListYouridealwashlabel:'一款理想型的水洗唛',
		washhightraitListNoneedof:'无需碳带打印，低成本更高效',

		washhightraitListApplicableindustries:'适用行业',
		washhightraitListMeettheneedsof:'满足多行业、多场景需求',

		washhightraitListShoesandhatsindustry:'鞋帽行业',
		washhightraitListSmoothsurface:'表面光滑，适用于标签信息、logo等介绍的承载，牢固不掉色',

		washhightraitListConsumergoodsindustry:'日用品行业',
		washhightraitListResistanttodry:'耐干摩擦，sgs耐摩擦检测达到4级以上',

		washhightraitListClothingindustry:'服装',
		washhightraitListUsedaslabels:'用在衣服标签，可以跟衣服一起用高温熨烫机熨烫，高温熨烫后不变形不发色',

		washhightraitListFoodandfeedingpackaging:'食品饲料包装行业',
		washhightraitListThermalprinting:'热敏打印更高效便捷，耐刮擦',

		tableDataHighTemperatureThermalWashLabel:'热敏高温水洗唛',

		tableDataNocolourfadingtitle:'耐水洗：',
		tableDataNocolourfading:'经过60°常温加洗衣液水洗，洗5次每次45分钟后依旧不褪色，不脱层；',

		tableDataScratchResistanttitle:'耐刮擦：',
		tableDataScratchResistant:'干摩擦色牢度4级以上；',
		
		tableDataLongshelflifetitle:'长久保存：',
		tableDataLongshelflife:"团队经过长时间研发的可长久保存的热敏材料，十年仍不褪色；",

		tableDataHighcolordensitytitle:'显色浓度高字迹清晰：',
		tableDataHighcolordensity:'热敏打印出来显色浓度高字迹清晰可见；',

		tableDataCostsavingandwastetitle:'节约成本降低排废：',
		tableDataCostsavingandwaste:'传统碳带打印的需要不定时更换碳带，增加成本。相对之下热敏打印不仅节约成本更是环保首选；',

		tableDataAlcoholresistanttitle:'防酒精：',
		tableDataAlcoholresistant:'可浸泡在酒精中超过5分钟不褪色；',

		washHighsize:'size.png',
		
		//热敏常温水洗唛
		washNormalProductFeatures:'产品特点',
		washNormalNormalTemperature:'常温水洗唛',

		washNormaltraitListWashable:'耐水洗',
		washNormaltraitListNocolourfading:'经过40°常温水洗，60分钟不褪色，不会脱层 ',
		washNormaltraitListScratchResistant:'耐刮擦',
		washNormaltraitListcolorfastness :'干摩擦色牢度4级以上',

	 washNormaltraitListLongshelflife:'长久保存',
	 washNormaltraitListteamdeveloped:"团队经过长时间研发的可长久保存的热敏材料，十年仍不褪色",
	 washNormaltraitListAlcoholresistant:'防酒精',
	 washNormaltraitListnocolorfading: '可浸泡在酒精中超过5分钟不褪色',
		
	 washNormaltraitListFourmajorcharacteristics:'四大特色',
	 washNormaltraitListLetyouchooseus:'让您选择我们',

	 washNormaltraitListCostsaving:'节约成本·降低排废',
	 washNormaltraitListIntraditional:'传统碳带打印的需要不定时更换碳带，增加成本。相对之下热敏打印不仅节约成本更是环保首选。',


	 washNormaltraitListTraditionalribbonprinting:'传统碳带打印',
	 washNormaltraitListwashNormaltraitList:'传统碳带-操作繁琐、不环保、限制多',

	 washNormaltraitListThermalprinting:'热敏打印无需碳带',
	 washNormaltraitListnoribbon:'热敏打印-无需碳带、打印成本低、环保、色浓度高字迹清晰',


	 washNormaltraitListProducts:'产品展示',
	 washNormaltraitListYouridealwashlabel:'一款理想型的水洗唛',

	 washNormaltraitListApplicableindustries:'适用行业',
	 washNormaltraitListMeettheneeds :'满足多行业、多场景需求',

	 washNormaltraitListHomeTextile:'家居纺织',
	 washNormaltraitListItcanbeused:'可以用作家居生活品，比如沙发藏标、床单被罩 、浴巾毛巾之类的标签，可以长久保存。',

	 washNormaltraitListLuggage:'箱包标签',
	 washNormaltraitListUsedongoods: '用作箱包等不需要高温熨烫的货品上',

	 washNormaltraitListPackaging:'包装',
	 washNormaltraitListPackagingsealing:'米面粮食等包装封口标，长时间保存无需碳带',

	 washNormaltraitListtag:'电子标签',
	 washNormaltraitListLabelsfor:'各种缠绕带线标',


	 washNormaltraitListWashabletitle:'耐水洗：',
	 washNormaltraitListWashableNocolour:' 经过40°常温水洗，60分钟不褪色，不会脱层；',

	 washNormaltraitListScratchResistanttitle:'耐刮擦：',
	 washNormaltraitListScratchResistant:'干摩擦色牢度4级以上；',
	washNormaltraitListLongshelflifetitle:'长久保存：',
	washNormaltraitListLongshelflife:"团队经过长时间研发的可长久保存的热敏材料，十年仍不褪色；",

	washNormaltraitListHighcolortitle:'显色浓度高字迹清晰：',
	washNormaltraitListHighcolor:'热敏打印出来显色浓度高字迹清晰可见；',

  washNormaltraitListCostsavingandwastetitle:'节约成本降低排废：',
	washNormaltraitListCostsavingandwaste:'传统碳带打印的需要不定时更换碳带，增加成本。相对之下热敏打印不仅节约成本更是环保首选；',
	washNormaltraitListAlcoholresistanttitle:'防酒精：',
	washNormaltraitListAlcoholresistant:'可浸泡在酒精中超过5分钟不褪色；',

	washNormaldisplay1:'display_01.jpg',
	washNormaldisplay2:'display_02.jpg',
	washNormaldisplay3:'display_03.png',

		//标签设计
		LabelDesign:'标签设计平台',
    Allinonesolutiontool:'一体化方案解决工具-创未来',
		MASFLabelEditor:'·  马斯夫标签编辑器  ·',
		introduce:'一款实现多行业标签打印的软件，并提供综合性解决方案。集在线设计、裁剪、任务下发、打印任务监督管理、领料管理、账号功能于一体，一站式完成条码设计、生产与管理',

		IntroductiontoFunction:'功能介绍',

		PDFImportSplit:'PDF导入-拆分',
		Noneedtomanually:'无需手动切分PDF模板，对一页存在多个标签进行单任务拆分',

		ControlofInkjetorthermalprinter:'喷墨、热敏设备控制',
		Supportsinkjetprinters:'支持添加喷墨打印机，对喷头等配置进行管理让您打印更加便捷无忧',

		DefaultandGeneral:'默认及修改常规设置',
		Printheadconfiguration:'对打印机喷头进行调配',
		Developermode:'开发者模式调整',

		BatchPrinting:'批量打印',
		Batchprintingcanbeachieved:'可按照客户或者订单分组实现批量打印',
		Supportsaddinggroupsintoprintqueues:'支持分组加入打印队列',
		Inkjetthermalprintingsupport:'支持喷墨/热敏打印',
		Supportbatchprinting:'支持批量打印',
		Inkjetprintingdevicemanagement:'支持喷墨打印设备管理',


		AccountManagement:'账号管理',
		Youcansetupteamdepartment:'可设置团队/部门账号，并对账号进行权限管理。',
		Assignprivilegestodifferentaccounts:'对不同的账号进行权限分配',
		Assigntaskstosubaccounts:'对子账号进行任务下发',

		TemplateCenter:'模板中心',
		Hugeamountoftemplates:'海量模板素材',
		Manykindsofmaterials:'素材种类多，涉及行业广',
		Collecttemplates:'可收藏模板，也可把标签分享至模板中心',
		Supporttemplatesediting:'支持编辑模板',

		WeProvideIntegrated:'为企业提供一体化智能解决方案',

		MASFCloudPrintingPlatform:'MASF云打印平台',
		Cloudprintingmanagementplatform:'云打印管理平台',
		Thefirstdomesticdual:'国内首家双色热敏打印解决方案商，双色小票内容重点突出、营销转化率高，能够有效提 升门店运营效率和复购率、降低因配餐失误、分拣错误等因素造成的运营成本。',

		trait_03:'trait_03.png',
		trait_04:'trait_04.png',
		display_01:'display_01.png',
		display_02:'display_02.png',
		display_03:'display_03.png',
		display_04:'display_04.png',
		display_05:'display_05.png',
		display_06:'display_06.png',

		//云打印平台
		cloudPrint:'云打印平台',
		

  }
}





