import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import zh from './zh'


Vue.use(VueI18n)

const messages = {
  en: {
    ...en
  },
  zh: {
    ...zh
  }
}


// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'zh', // set locale   //注意local 的value是啥就是啥语言 en为英语，zh为中文
  messages, // set locale messages
})
export default i18n 
