<template>
  <div class="home">
    <Header/>
    <!-- <Menu/> -->
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
const Header = ()=>import('@c/header.vue')
const Menu = ()=>import('@c/menu.vue')
const Footer =()=>import("@c/footer.vue")
export default {
  name: 'Home',
  components: {
   Header,
   Menu,
   Footer
  }
}
</script>
<style lang="less" scoped>

</style>
