import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from "@/lang/"
Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
   {
    path: '/:language?',
    name: 'Home',
    component: Home,
    children: [{
      path: '/:language?',
      name: '0',
      component: () => import('@v/index.vue')
    },
    {
      path:'/product',
      name:'1',
      component:()=>import('@v/product.vue')
    },
    {
      path:'/aboutUs',
      name:'2',
      component:()=>import('@v/aboutUs.vue')
    },
    {
      path:'/contactUs',
      name:'3',
      component:()=>import('@v/contactUs.vue')
    },
		{
		  path: '/material/paperBicolor/:language?',
		  component: () => import('@v/material/paperBicolor'),
		  name: 'paperBicolor',
		  meta: { title: '双色热敏纸', current: 4 }
		},
		{
		  path: '/material/paperCompound/:language?',
		  component: () => import('@v/material/paperCompound'),
		  name: 'paperCompound',
		  meta: { title: '热敏合成纸', current: 5 }
		},
		{
		  path: '/material/paperFourproofing/:language?',
		  component: () => import('@v/material/paperFourproofing'),
		  name: 'paperFourproofing',
		  meta: { title: '四防热敏纸', current: 6 }
		},
		{
		  path: '/material/paperCopper/:language?',
		  component: () => import('@v/material/paperCopper'),
		  name: 'paperCopper',
		  meta: { title: '热敏铜版纸', current: 7 }
		},
		{
		  path: '/material/washNormal/:language?',
		  component: () => import('@v/material/washNormal'),
		  name: 'washNormal',
		  meta: { title: '常温水洗唛', current: 8 }
		},
		{
		  path: '/material/washHigh/:language?',
		  component: () => import('@v/material/washHigh'),
		  name: 'washHigh',
		  meta: { title: '高温水洗唛', current: 9 }
		},
  ]


  },
	{
	  path: '/platform/:language?',
	  component: () => import('@v/platform'),
	  name: 'platform',
	  meta: { title: '标签设计平台', current: 3 }
	},
	{
    path: '/cloudPrint/:language?',
	  component: () => import('@v/cloudPrint'),
	  name: 'cloudPrint',
	  meta: { title: '云打印平台', current: 3 }
	},
	{
	  path: '*',
	  redirect:'/'
	},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {  
	let arr=['zh','en']
  const lang = to.params.language || 'zh';
	
  // 设置当前语言状态，这里假设你有一个setLanguage方法来设置语言状态 
	if(arr.indexOf(lang)!=-1){
		i18n.locale=lang
	}
  next();  
});  
  

export default router
