<template>
  <div id="app">
    <router-view />
    <BackTop/>
  </div>
</template>
<script>
const BackTop = () => import("@c/backToTop.vue");
export default {
  components: {
    BackTop,
  },
};
</script>
<style lang="less">
@import "assets/css/menu.css";
@import "assets/css/flex.css";
</style>
