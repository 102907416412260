import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/assets/css/reset.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'wowjs/css/libs/animate.css'
import 'default-passive-events'
import animated from 'animate.css'

import i18n from './lang/index'

Vue.use(animated)
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
	i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
